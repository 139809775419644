<template>
<b-card
    no-body
>
  <div class="m-2">
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
        <label>Entries</label>
        <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
        />
        <b-button
            variant="primary"
            :to="{ name: 'group-email-add'}"
        >
            Add Group Mail
        </b-button>
        </b-col>

        <!-- Search -->
        <b-col
        cols="12"
        md="6"
        >
        <div class="d-flex align-items-center justify-content-end">
            <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1"
            placeholder="Search..."
            />
            
        </div>
      </b-col>
    </b-row>
  </div>
  <b-table
    ref="refMailGroupListTable"
    :items="fetchMailGroups"
    responsive
    striped
    :fields="tableColumns"
    primary-key="id"
    :sort-by.sync="sortBy"
    show-empty
    empty-text="No matching records found"
    :sort-desc.sync="isSortDirDesc"
    class="position-relative"
  >
    <template #cell(title)="data">
             
               
        <router-link :to="{ name: 'group-email-edit', params: { id: data.item.id } }" >{{ data.item.title }}</router-link>
    </template>
    <template #cell(is_active)="data">

      <b-form-checkbox
          v-if="data.item.is_active == 1"
          checked="true"
          class="custom-control-primary"
          name="is_active"
          switch
        />
      <b-form-checkbox
          v-else
          checked="false"
          class="custom-control-primary"
          name="is_active"
          switch
        />
    </template>
    <template #cell(actions)="data">
      <span>
        <b-dropdown
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template v-slot:button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="text-body align-middle mr-25"
            />
          </template>
          <b-dropdown-item :to="{ name: 'group-email-edit', params: { id: data.item.id } }">
            <feather-icon
              icon="Edit2Icon"
              class="mr-50"
            />
            <span>Edit</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteconfirm(data.item.id)">
            <feather-icon
              icon="TrashIcon"
              class="mr-50"
            />
            <span>Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </span>
        
    </template>
  </b-table>
</b-card>
</template>
<script>
import {
  BCard, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,BImg,BFormSelect
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import vSelect from 'vue-select'

import useGroupMailList from './useGroupmailList'

import groupmailStoreModule from '../groupmailStoreModule'
import Ripple from 'vue-ripple-directive'
export default {
  components: {
     BCard,
        BRow,
        BCol,
        BFormInput,
        BFormCheckbox,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTooltip,
        BImg,
        vSelect,
        BFormSelect
  },
  setup() {
    const GROUP_MAIL_APP_STORE_MODULE_NAME = 'app-group-mail'
    if (!store.hasModule(GROUP_MAIL_APP_STORE_MODULE_NAME)) store.registerModule(GROUP_MAIL_APP_STORE_MODULE_NAME, groupmailStoreModule)
    const imgPath = store.state.appConfig.layout.imgPath
   
    // UnRegister on leave
    onUnmounted(() => {
    
        if (store.hasModule(GROUP_MAIL_APP_STORE_MODULE_NAME)) store.unregisterModule(GROUP_MAIL_APP_STORE_MODULE_NAME)
    })
    const {
          fetchMailGroups,
     
      tableColumns,
      perPage,
      currentPage,
      totalGroupMails,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMailGroupListTable,
      deleteData,
      refetchData,
        } = useGroupMailList()
        return {
          fetchMailGroups,
          imgPath,
          tableColumns,
          perPage,
          currentPage,
          totalGroupMails,
          dataMeta,
          perPageOptions,
          searchQuery,
          sortBy,
          isSortDirDesc,
          refMailGroupListTable,
          deleteData,
         
          refetchData,
        }
  },
  directives: {
    Ripple,
  },
  methods:{
    deleteconfirm(id){
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Blog ID.'+id, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value === true){
            this.deleteData(id)
          }
          console.log('value',value);
          //this.deleteData(id)
        })
      
    }
  },
}
</script>
