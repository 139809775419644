import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useGroupmailList() {
    const toast = useToast()
    const refMailGroupListTable = ref(null)
    const tableColumns = [
        { key: 'id', label: 'ID', sortable: true },
      
        { key: 'title',label:'Title', sortable: true },
     
    
        { key: 'actions' },
        
    ]
    const perPage = ref(10)
    const totalGroupMails = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
  
   
    const dataMeta = computed(() => {
    const localItemsCount = refMailGroupListTable.value ? refMailGroupListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalBlog.value,
        }
    })
    const refetchData = () => {
    
        refMailGroupListTable.value.refresh()
    }
    watch([currentPage, perPage, searchQuery], () => {
        console.log('change');
        refetchData()
    })
    const fetchMailGroups = (ctx, callback)=>{
        
        store
        .dispatch('app-group-mail/fetchGroupMail', {
          limit:10,
          offset:0,
        })
        .then(response => {
          
         
          const blogs = response.data.data;
          console.log('blogs',response.data);
        //  const { invoices, total } = response.data
         callback(blogs)
         // callback(invoices)
          //totalInvoices.value = total
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching invoices' list",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        
      }
      const deleteData = (id) => {
        //fetchDeleteProperty
        store.dispatch('app-group-mail/fetchDelete',id).then(response=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Delete Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData();
        }).catch(()=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Delete Blog",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })
        //console.log('id', id);
        
      }

    return {
        fetchMailGroups,
      
      tableColumns,
      perPage,
      currentPage,
      totalGroupMails,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMailGroupListTable,
      deleteData,
      refetchData
      
    }
}